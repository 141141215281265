<template>
  <div class="label-list">
    <el-form :inline="true" class="form-content">
		
      <el-form-item >

       <el-input
          placeholder="输入套餐名称查询"
          v-model="title"
          class="input-with-select"
          style="width: 400px"
        >
		
          <el-button
            slot="append"
            @click="getList"
            icon="el-icon-search"
          ></el-button>
        </el-input>
      </el-form-item>
<!--     <el-form-item label="状态">
       <el-select placeholder="请选择" v-model="status" clearable style="width:200px;">
        <el-option
        
          :value="1"
          label="启用"
        ></el-option>
		<el-option
		
		  :value="2"
		  label="禁用"
		></el-option>
        </el-select>
      </el-form-item> -->
	  <el-form-item>
	  	<el-button @click="getList()" type="primary">搜索</el-button>
	  	<el-button @click="reset()" type="reset">重置</el-button>
	  </el-form-item>
    </el-form>
    <div class="search-list">
      <el-button type="primary" @click="add">添加</el-button>
      <el-button
        type="danger"
        @click="deleteHandle"
        :disabled="dataListSelections.length ? false : true"
        >删除</el-button
      >
    </div>
    <div class="table">
      <el-table
        :data="dataList"
        style="width: 100%"
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
      >
        <el-table-column type="selection" width="50"></el-table-column>
      
     
        <el-table-column  label="测评套餐名称">
			<template slot-scope="scope">
				 <el-button type="text" @click="gotoInfo(scope.row)">{{scope.row.packageName}}</el-button>
			</template>
		</el-table-column>


	  <el-table-column prop="createName" label="状态">
		  
		  <template slot-scope="scope">
		    <span v-if="scope.row.status==1"> 启用</span> 
			  <span v-if="scope.row.status==2"> 禁用</span> 
		  </template>
	  </el-table-column>
  <el-table-column   label="添加时间">
	  <template slot-scope="scope">
	  	<div style="padding: 11px;">{{scope.row.createTime}}</div>
	  </template>
  </el-table-column>
        <el-table-column label="操作" header-align="right" align="right">
          <template slot-scope="scope">
            <el-button type="text" @click="add(scope.row)" >编辑</el-button>
			 <el-button type="text" @click="setting(scope.row)" >配置</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
    
  </div>
</template>
<script>
import Qs from "qs";
import moment from "moment";
export default {
  name: "label",
  data() {
    return {
      title: "",
	  select: "1",
      status: "",
      dataList: [],
      dataListLoading: true, //列表loading
      dataListSelections: [], //选中的列
      pageNo: 1,
      pageSize: 10,
      totalPage: 0, //总页数
      dateRangeTime: [],
      teamsManage: [],
      typeList: [
       
      ],
      visible: false,
      form: {
        title: "",
      },
      dataRules: {
        title: [{ required: true, message: "请输入任务主题", trigger: "blur" }],
      },
      edit: false,
      flag: true, //防止重复点击
      visible2: false,
      dataList2: [],
      dataListLoading2: true, //列表loading
      dataListSelections2: [], //选中的列
      pageNo2: 1,
      pageSize2: 10,
      totalPage2: 0, //总页数
    };
  },
  async mounted() {
	      await this.getTypeList();
    await this.getList();
  },
  methods: {
    async getList() {

      let params = {

        stringParam1: this.title,
		stringParam2:this.status,
        pageSize: this.pageSize,
        pageNo: this.pageNo,
      };

      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/evaluationPackage/list"),
        method: "post",
        data: params,
		contentType:'json',
		headers: {
			'Content-Type': 'application/json',
		   				
		},
      });
      if (res.status) {
        this.dataList = res.data;
        this.totalPage = res.totalCount;
		this.dataList.forEach(item=>{
			item.createTime = moment(new Date(item.createTime)).format('YYYY-MM-DD HH:mm:ss')

		})
      }
      this.dataListLoading = false;
    },
	gotoInfo(item) {
		let routeData = this.$router.resolve({
       name: "evaluationPackageInfo",
       query: { id: item.id },
     });
		             window.open(routeData.href, '_blank');
			
   
    },
	setting(item) {
      if (item && item.id) {
        // this.form = JSON.parse(JSON.stringify(item));
        this.$router.push({
          name: "evaluationPackageSetting",
          query: { id: item.id },
        });
      } else {
	
       
      }

    },
    add(item) {
      if (item && item.id) {
     let routeData = this.$router.resolve({
          name: "evaluationPackageUpdate",
          query: { id: item.id },
        });
                  window.open(routeData.href, '_blank');
     
      } else {
		  let routeData = this.$router.resolve({
		    name: "evaluationPackageAdd",
		  });
		               window.open(routeData.href, '_blank');
	
       
      }

    },
    cancel() {
      this.$refs["form"].resetFields();
      this.visible = false;
      this.form = {
        title: "",
      };
    },
    async submit() {
      let params = {
        title: this.form.title,
        // status: 1,
      };
      if (localStorage.currentTeam) {
        params.teamsId = JSON.parse(localStorage.currentTeam).id;
      }
      if (this.form.id) {
        params.id = this.form.id;
      }
      // console.log(params);
      this.$refs.form.validate(async (valid) => {
        if (this.flag) {
          this.flag = false;
          if (valid) {
            const { data: res } = await this.$http({
              url: this.$http.adornUrl(
                this.form.id ? "/mbglLabelIndex/update" : "/mbglLabelIndex/add"
              ),
              method: "post",
              data: Qs.stringify(params),
            });
            if (res.status) {
              this.$message({
                message: res.msg,
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.flag = true;
                  this.visible = false;
                  this.getList();
                  if (res.data && res.data) {
                    this.$router.push({
                      name: "addCaseSearch",
                      query: { id: res.data },
                    });
                  }
                },
              });
            } else {
              this.$message.error(res.msg);
              this.flag = true;
            }
          } else {
            return false;
          }
        }
      });
    },
   
   async getTypeList() {
     let params = {
       stringParam1: "病种",
     };
     // if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
     const { data: res } = await this.$httpAes({
       url: this.$httpAes.adornUrl("/dict/list"),
       method: "post",
       data: params,
   	contentType:'json',
   	headers: {
   		'Content-Type': 'application/json',
   	   				
   	},
     });
     // console.log(res,'res')
     this.typeList = res.data;
   },

   async deleteHandle() {
      let that = this;
      let ids = null;
     
        ids = that.dataListSelections.map((item) => {
          return item.id;
        });
        ids = ids.join(",");
   
      that
        .$confirm(`确定进行删除操作?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
			
			that
			  .$httpAes({
			    url: this.$httpAes.adornUrl("/evaluationPackage/delete"),
			    method: "post",
			     data: { stringParam1: ids },
				contentType:'json',
				headers: {
					'Content-Type': 'application/json',
				   				
				},
			  })
			  .then(({ data }) => {
			    if (data.status) {
			      that.$message({
			        message: data.msg,
			        type: "success",
			        duration: 1500,
			        onClose: () => {
			           that.getList();
			        },
			      });
			    } else {
			      that.$message.error(data.msg);
			    }
			  });
			
		
	
        })
        .catch(() => {});
    },
    reset() {
      this.status = "";
      this.title = "";
 
      this.getList();
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 显示下载列表
    downloadData(row) {
      this.visible2 = true;
      this.getDataList(row.id);
    },
    // 下载内容
    async getDataList(id) {
      let params = {
        pageSize: this.pageSize2,
        pageNo: this.pageNo2,
        retrievalId: id,
      };
      const { data: res } = await this.$http({
        url: this.$http.adornUrl("/evaluationPackage/list"),
        method: "get",
        params: params,
      });
      if (res.status) {
        this.dataList2 = res.data;
        this.totalPage2 = res.totalCount;
      }
      this.dataListLoading2 = false;
    },
    // 每页数
    sizeChangeHandle2(val) {
      this.pageSize2 = val;
      this.pageNo2 = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle2(val) {
      this.pageNo2 = val;
      this.getDataList();
    },
    // 添加检索
    addQuestionSearch(row) {
      let params = { retrievalId: row.id };
      this.$http({
        url: this.$http.adornUrl("/mbglRetrieveRecords/add"),
        method: "post",
        data: Qs.stringify(params),
      }).then(({ data }) => {
        if (data.status) {
          this.updateStatus(row.id);
        }
      });
    },
    updateStatus(id) {
      let params = { ids: id, status: 3 };
      this.$http({
        url: this.$http.adornUrl("/mbglLabelIndex/updateStatusByIds"),
        method: "post",
        data: Qs.stringify(params),
      }).then(({ data }) => {
        if (data.status) {
          this.$message({
            message: "重新检索成功",
            type: "success",
            duration: 1500,
            onClose: () => {
              this.$router.push({
                name: "caseSearch",
              });
            },
          });
        }
      });
    },
    // 下载
    downloadUrl(row) {
      var that = this;
      let link = document.createElement("a");
      link.href = row.url;
      link.click();
    },
  },
};
</script>

<style lang="scss" scoped>
	.el-select .el-input {
	    width: 130px;
	  }
</style>
